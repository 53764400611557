<template>
  <el-row>
    <el-col :md="12">
      <el-card class="box-card">
        <div slot="header" class="clearfix">
          <span>我的小会员</span>
        </div>
        <div v-if="myVip !== null" class="text item">
          <el-row v-if="!myVip.isVip">
            您当前还不是小会员, <el-button type="text" @click="showPlanDialog">成为小会员</el-button>
          </el-row>
          <el-row v-else>
            <el-row v-if="myVip.expired">
              您的小会员已过期, <el-button type="text" @click="showPlanDialog">续费小会员</el-button>
            </el-row>
            <el-row v-else>
              您的小会员还有 <span style="color: red">{{ myVip.expireIn }}</span> 天到期, <el-button type="text" @click="showPlanDialog">续费小会员</el-button>
            </el-row>
          </el-row>
        </div>
      </el-card>
    </el-col>
    <el-col>
      <div style="width: 500px; height: 100px; position: relative">
        <StampBadge
          size="small"
          color="warning"
          content="小会员"
          :rotate="0"
        />
      </div>
      <div style="width: 500px; height: 100px; position: relative">
        <StampBadge
          style="position: absolute; top: 0; right: 0"
          size="middle"
          color="success"
          content="已支付"
          :rotate="45"
        />
      </div>
    </el-col>

    <el-dialog
      title="小会员计划"
      append-to-body
      :visible.sync="planDialog"
      width="50%"
      center
    >
      <el-form ref="form" :model="planForm">
        <el-form-item>
          <el-radio-group v-for="(item, index) in vipPlans" :key="index" v-model="planForm.planId">
            <el-radio style="margin-right: 5px" :label="item.planId" border>
              {{ item.name }} (<span style="color: red">¥{{ item.price }}</span> 元)
            </el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            @click="buy"
          >购买</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </el-row>
</template>

<script>
import StampBadge from '@/components/StampBadge'
import { getMyVip, getVipPlans, vip } from '@/api/account'

export default {
  name: 'MyVip',
  components: { StampBadge },
  data() {
    return {
      planDialog: false,
      vipPlans: [],
      planForm: {
        planId: null,
        quantity: null
      },
      myVip: null
    }
  },
  created() {
    document.title = '我的小会员'
    getMyVip().then(resp => {
      if (resp.code === 0) {
        this.myVip = resp.data
      }
    })
  },
  mounted() {
  },
  methods: {
    showPlanDialog() {
      this.planDialog = true
      getVipPlans().then(resp => {
        if (resp.code === 0) {
          this.vipPlans = resp.data
          console.log(this.vipPlans)
        }
      })
    },
    buy() {
      this.planDialog = false
      vip(this.planForm).then(resp => {
        if (resp.code === 0) {
          this.$notify.info({
            message: '小会员已开通',
            duration: 3000
          })
        } else {
          this.$notify.error({
            message: resp.msg,
            duration: 3000
          })
        }
      })
    }
  }
}
</script>

<style scoped>
</style>
